import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const initialCategories = [
  'Pre-Production',
  'Production',
  'Post-Production',
  'Marketing and Promotion',
  'Distribution',
  'Miscellaneous'
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

const BudgetTracker = () => {
  const [budget, setBudget] = useState(() => {
    const savedBudget = localStorage.getItem('budget');
    return savedBudget ? parseFloat(savedBudget) : 0;
  });
  const [expenses, setExpenses] = useState(() => {
    const savedExpenses = localStorage.getItem('expenses');
    return savedExpenses ? JSON.parse(savedExpenses) : [];
  });
  const [newExpense, setNewExpense] = useState({ category: '', description: '', amount: '' });
  const [error, setError] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    localStorage.setItem('budget', budget.toString());
  }, [budget]);

  useEffect(() => {
    localStorage.setItem('expenses', JSON.stringify(expenses));
  }, [expenses]);

  const addOrUpdateExpense = () => {
    if (!newExpense.category || !newExpense.description || !newExpense.amount) {
      setError('Please fill in all fields');
      return;
    }
    if (editingIndex !== null) {
      const updatedExpenses = [...expenses];
      updatedExpenses[editingIndex] = { ...newExpense, amount: parseFloat(newExpense.amount) };
      setExpenses(updatedExpenses);
      setEditingIndex(null);
    } else {
      setExpenses([...expenses, { ...newExpense, amount: parseFloat(newExpense.amount) }]);
    }
    setNewExpense({ category: '', description: '', amount: '' });
    setError('');
  };

  const startEditing = (index) => {
    setNewExpense(expenses[index]);
    setEditingIndex(index);
  };

  const deleteExpense = (index) => {
    const updatedExpenses = expenses.filter((_, i) => i !== index);
    setExpenses(updatedExpenses);
  };

  const totalExpenses = expenses.reduce((sum, expense) => sum + expense.amount, 0);
  const remainingBudget = budget - totalExpenses;

  const chartData = useMemo(() => {
    const data = initialCategories.map(category => ({
      name: category,
      value: expenses
        .filter(expense => expense.category === category)
        .reduce((sum, expense) => sum + expense.amount, 0)
    }));
    return data.filter(item => item.value > 0);
  }, [expenses]);

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center"></CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <label className="block mb-2">Total Budget:</label>
          <Input
            type="number"
            value={budget}
            onChange={(e) => setBudget(parseFloat(e.target.value))}
            placeholder="Enter total budget"
            className="w-full"
          />
        </div>
        
        <div className="mb-4">
          <label className="block mb-2">Add New Expense:</label>
          <div className="flex flex-col space-y-2">
            <select
              value={newExpense.category}
              onChange={(e) => setNewExpense({ ...newExpense, category: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="">Select Category</option>
              {initialCategories.map((cat) => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
            <Input
              type="text"
              value={newExpense.description}
              onChange={(e) => setNewExpense({ ...newExpense, description: e.target.value })}
              placeholder="Description"
              className="w-full"
            />
            <Input
              type="number"
              value={newExpense.amount}
              onChange={(e) => setNewExpense({ ...newExpense, amount: e.target.value })}
              placeholder="Amount"
              className="w-full"
            />
          </div>
          <Button onClick={addOrUpdateExpense} className="mt-2 w-full">
            {editingIndex !== null ? 'Update Expense' : 'Add Expense'}
          </Button>
        </div>

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Expense Summary:</h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `RM${value.toFixed(2)}`} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Expense List:</h3>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          >
            <option value="">All Categories</option>
            {initialCategories.map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
          <div className="space-y-2">
            {expenses
              .filter(expense => !selectedCategory || expense.category === selectedCategory)
              .map((expense, index) => (
                <div key={index} className="flex justify-between items-center border-b pb-2">
                  <span>{expense.category}: {expense.description}</span>
                  <div>
                    <span className="mr-2">RM{expense.amount.toFixed(2)}</span>
                    <Button onClick={() => startEditing(index)} className="mr-1">Edit</Button>
                    <Button onClick={() => deleteExpense(index)} variant="destructive">Delete</Button>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        <div>Total Expenses: RM{totalExpenses.toFixed(2)}</div>
        <div className={remainingBudget >= 0 ? 'text-green-600' : 'text-red-600'}>
          Remaining Budget: RM{remainingBudget.toFixed(2)}
        </div>
      </CardFooter>
    </Card>
  );
};

export default BudgetTracker;