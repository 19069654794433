export const initialProjectData = {

  
  phases: [
    { name: "Pre-Production", start: "Aug 1", end: "Aug 15" },
    { name: "Production", start: "Aug 16", end: "Sep 20" },
    { name: "Post-Production", start: "Sep 21", end: "Sep 27" },
    { name: "Marketing and Promotion", start: "Sep 28", end: "Oct 18" },
    { name: "Release and Distribution", start: "Oct 19", end: "Oct 19" },
    { name: "Post-Release Evaluation", start: "Oct 20", end: "TBD" }
  ],
  tasks: [
    {
      id: 1,
      name: "Concept Finalization",
      phase: "Pre-Production",
      start: "Aug 1",
      end: "Aug 3",
      progress: 0,
      roles: ["Executive Producer", "A&R Representative", "Lead Producer", "Artist (0108 SLATAN)"],
      subtasks: [
        { id: 1.1, name: "Review album concept and objectives", duration: "1 day", roles: ["Executive Producer", "A&R Representative", "Lead Producer", "Artist (0108 SLATAN)"] },
        { id: 1.2, name: "Finalize track list and individual song concepts", duration: "1 day", roles: ["A&R Representative", "Lead Producer", "Artist (0108 SLATAN)"] },
        { id: 1.3, name: "Identify traditional Malaysian instruments to be incorporated", duration: "1 day", roles: ["Lead Producer", "Artist (0108 SLATAN)"] }
      ]
    },
    {
      id: 2,
      name: "Composition and Arrangement",
      phase: "Pre-Production",
      start: "Aug 4",
      end: "Aug 13",
      progress: 0,
      roles: ["Lead Producer", "Artist (0108 SLATAN)", "Songwriters", "Arrangers"],
      subtasks: [
        { id: 2.1, name: "Develop melodies and chord progressions for each track", duration: "3 days", roles: ["Lead Producer", "Artist (0108 SLATAN)", "Songwriters"] },
        { id: 2.2, name: "Create initial beat and rhythm structures", duration: "2 days", roles: ["Lead Producer", "Artist (0108 SLATAN)"] },
        { id: 2.3, name: "Arrange traditional and modern elements for each song", duration: "3 days", roles: ["Lead Producer", "Arrangers"] },
        { id: 2.4, name: "Finalize lyrics for all tracks", duration: "2 days", roles: ["Artist (0108 SLATAN)", "Songwriters"] }
      ]
    },
    {
      id: 3,
      name: "Pre-Production Planning",
      phase: "Pre-Production",
      start: "Aug 14",
      end: "Aug 15",
      progress: 0,
      roles: ["Production Coordinator", "Studio Manager", "Lead Producer", "Sound Engineer"],
      subtasks: [
        { id: 3.1, name: "Schedule studio time for recording sessions", duration: "0.5 day", roles: ["Production Coordinator", "Studio Manager"] },
        { id: 3.2, name: "Coordinate with session musicians and featured artists", duration: "0.5 day", roles: ["Production Coordinator"] },
        { id: 3.3, name: "Prepare production notes for each track", duration: "0.5 day", roles: ["Lead Producer"] },
        { id: 3.4, name: "Create detailed recording plan", duration: "0.5 day", roles: ["Lead Producer", "Sound Engineer"] }
      ]
    },
    {
      id: 4,
      name: "Recording Sessions",
      phase: "Production",
      start: "Aug 16",
      end: "Sep 1",
      progress: 0,
      roles: ["Lead Producer", "Sound Engineer", "Artist (0108 SLATAN)", "Assistant Engineers", "Session Musicians"],
      subtasks: [
        { id: 4.1, name: "Record main vocals for each track", duration: "5 days", roles: ["Lead Producer", "Sound Engineer", "Artist (0108 SLATAN)", "Assistant Engineers"] },
        { id: 4.2, name: "Record featured artists' parts", duration: "3 days", roles: ["Lead Producer", "Sound Engineer", "Assistant Engineers"] },
        { id: 4.3, name: "Record traditional Malaysian instruments", duration: "4 days", roles: ["Lead Producer", "Sound Engineer", "Session Musicians", "Assistant Engineers"] },
        { id: 4.4, name: "Record modern instruments (guitars, keyboards, etc.)", duration: "3 days", roles: ["Lead Producer", "Sound Engineer", "Session Musicians", "Assistant Engineers"] },
        { id: 4.5, name: "Record additional percussion and beats", duration: "2 days", roles: ["Lead Producer", "Sound Engineer", "Session Musicians", "Assistant Engineers"] }
      ]
    },
    {
      id: 5,
      name: "Editing",
      phase: "Production",
      start: "Sep 2",
      end: "Sep 8",
      progress: 0,
      roles: ["Sound Engineer", "Assistant Engineers"],
      subtasks: [
        { id: 5.1, name: "Compile and organize recorded tracks", duration: "1 day", roles: ["Sound Engineer", "Assistant Engineers"] },
        { id: 5.2, name: "Edit vocal performances", duration: "2 days", roles: ["Sound Engineer"] },
        { id: 5.3, name: "Edit instrumental performances", duration: "2 days", roles: ["Sound Engineer"] },
        { id: 5.4, name: "Adjust timing and pitch where necessary", duration: "2 days", roles: ["Sound Engineer"] }
      ]
    },
    {
      id: 6,
      name: "Mixing",
      phase: "Production",
      start: "Sep 9",
      end: "Sep 16",
      progress: 0,
      roles: ["Mixing Engineer", "Lead Producer"],
      subtasks: [
        { id: 6.1, name: "Balance levels of all tracks", duration: "3 days", roles: ["Mixing Engineer"] },
        { id: 6.2, name: "Apply effects and processing", duration: "2 days", roles: ["Mixing Engineer"] },
        { id: 6.3, name: "Create stereo image and depth", duration: "2 days", roles: ["Mixing Engineer"] },
        { id: 6.4, name: "Ensure cohesive sound across all songs", duration: "1 day", roles: ["Mixing Engineer", "Lead Producer"] }
      ]
    },
    {
      id: 7,
      name: "Mastering",
      phase: "Production",
      start: "Sep 17",
      end: "Sep 20",
      progress: 0,
      roles: ["Mastering Engineer"],
      subtasks: [
        { id: 7.1, name: "Finalize overall sound of the album", duration: "2 days", roles: ["Mastering Engineer"] },
        { id: 7.2, name: "Ensure consistent volume and tone across tracks", duration: "1 day", roles: ["Mastering Engineer"] },
        { id: 7.3, name: "Prepare different formats for various distribution channels", duration: "1 day", roles: ["Mastering Engineer"] }
      ]
    },
    {
      id: 8,
      name: "Quality Control",
      phase: "Post-Production",
      start: "Sep 21",
      end: "Sep 23",
      progress: 0,
      roles: ["Executive Producer", "A&R Representative", "Lead Producer", "Artist (0108 SLATAN)", "Mastering Engineer"],
      subtasks: [
        { id: 8.1, name: "Review final mixes and masters", duration: "1 day", roles: ["Executive Producer", "A&R Representative", "Lead Producer", "Artist (0108 SLATAN)"] },
        { id: 8.2, name: "Conduct listening sessions with key stakeholders", duration: "1 day", roles: ["Executive Producer", "A&R Representative"] },
        { id: 8.3, name: "Make final adjustments based on feedback", duration: "1 day", roles: ["Mastering Engineer"] }
      ]
    },
    {
      id: 9,
      name: "Artwork and Packaging",
      phase: "Post-Production",
      start: "Sep 24",
      end: "Sep 26",
      progress: 0,
      roles: ["Graphic Designer", "Production Coordinator"],
      subtasks: [
        { id: 9.1, name: "Design album cover and artwork", duration: "1 day", roles: ["Graphic Designer"] },
        { id: 9.2, name: "Create digital booklet with lyrics and credits", duration: "1 day", roles: ["Graphic Designer", "Production Coordinator"] },
        { id: 9.3, name: "Prepare packaging for physical releases", duration: "1 day", roles: ["Production Coordinator"] }
      ]
    },
    {
      id: 10,
      name: "Legal and Administrative",
      phase: "Post-Production",
      start: "Sep 27",
      end: "Sep 27",
      progress: 0,
      roles: ["Legal Advisor", "Production Coordinator"],
      subtasks: [
        { id: 10.1, name: "Clear all samples and features", duration: "0.3 day", roles: ["Legal Advisor"] },
        { id: 10.2, name: "Finalize credits and liner notes", duration: "0.3 day", roles: ["Production Coordinator", "Legal Advisor"] },
        { id: 10.3, name: "Register songs with appropriate rights organizations", duration: "0.4 day", roles: ["Legal Advisor"] }
      ]
    },
    {
      id: 11,
      name: "Marketing Strategy Development",
      phase: "Marketing and Promotion",
      start: "Sep 28",
      end: "Sep 30",
      progress: 0,
      roles: ["Marketing Manager", "A&R Representative", "Production Coordinator"],
      subtasks: [
        { id: 11.1, name: "Define target audience and key messages", duration: "1 day", roles: ["Marketing Manager", "A&R Representative"] },
        { id: 11.2, name: "Develop social media campaign strategy", duration: "1 day", roles: ["Marketing Manager"] },
        { id: 11.3, name: "Plan release events and listening parties", duration: "1 day", roles: ["Marketing Manager", "Production Coordinator"] }
      ]
    },
    {
      id: 12,
      name: "Promotional Content Creation",
      phase: "Marketing and Promotion",
      start: "Oct 1",
      end: "Oct 5",
      progress: 0,
      roles: ["Marketing Manager", "Production Coordinator", "Sound Engineer", "PR Specialist"],
      subtasks: [
        { id: 12.1, name: "Produce behind-the-scenes content", duration: "2 days", roles: ["Marketing Manager", "Production Coordinator"] },
        { id: 12.2, name: "Create teaser videos and audio snippets", duration: "2 days", roles: ["Marketing Manager", "Sound Engineer"] },
        { id: 12.3, name: "Develop press kit materials", duration: "1 day", roles: ["PR Specialist"] }
      ]
    },
    {
      id: 13,
      name: "Media Outreach",
      phase: "Marketing and Promotion",
      start: "Oct 6",
      end: "Oct 18",
      progress: 0,
      roles: ["PR Specialist", "A&R Representative"],
      subtasks: [
        { id: 13.1, name: "Coordinate interviews and press appearances", duration: "5 days", roles: ["PR Specialist"] },
        { id: 13.2, name: "Arrange radio and streaming playlist submissions", duration: "4 days", roles: ["PR Specialist", "A&R Representative"] },
        { id: 13.3, name: "Engage with music bloggers and influencers", duration: "4 days", roles: ["PR Specialist"] }
      ]
    },
    {
      id: 14,
      name: "Digital Distribution",
      phase: "Release and Distribution",
      start: "Oct 19",
      end: "Oct 19",
      progress: 0,
      roles: ["Production Coordinator", "Marketing Manager"],
      subtasks: [
        { id: 14.1, name: "Upload album to digital distribution platforms", duration: "0.2 day", roles: ["Production Coordinator"] },
        { id: 14.2, name: "Ensure all metadata and artwork are correct", duration: "0.2 day", roles: ["Production Coordinator"] },
        { id: 14.3, name: "Coordinate release timing across all platforms", duration: "0.1 day", roles: ["Marketing Manager", "Production Coordinator"] }
      ]
    },
    {
      id: 15,
      name: "Physical Distribution",
      phase: "Release and Distribution",
      start: "Oct 19",
      end: "Oct 19",
      progress: 0,
      roles: ["Production Coordinator", "Marketing Manager"],
      subtasks: [
        { id: 15.1, name: "Coordinate with manufacturers for CD/vinyl production", duration: "0.1 day", roles: ["Production Coordinator"] },
        { id: 15.2, name: "Arrange shipping and distribution to retailers", duration: "0.1 day", roles: ["Production Coordinator"] },
        { id: 15.3, name: "Set up pre-order system for physical copies", duration: "0.1 day", roles: ["Marketing Manager"] }
      ]
    },
    {
      id: 16,
      name: "Release Day Activities",
      phase: "Release and Distribution",
      start: "Oct 19",
      end: "Oct 19",
      progress: 0,
      roles: ["Marketing Manager", "PR Specialist", "Artist (0108 SLATAN)", "Production Coordinator", "Sound Engineer"],
      subtasks: [
        { id: 16.1, name: "Monitor and respond to fan reactions", duration: "0.1 day", roles: ["Marketing Manager", "PR Specialist"] },
        { id: 16.2, name: "Engage with audience on social media platforms", duration: "0.1 day", roles: ["Marketing Manager", "Artist (0108 SLATAN)"] },
        { id: 16.3, name: "Address any technical issues with digital or physical releases", duration: "ongoing", roles: ["Production Coordinator", "Sound Engineer"] }
      ]
    },
    {
      id: 17,
      name: "Performance Analysis",
      phase: "Post-Release Evaluation",
      start: "Oct 20",
      end: "TBD",
      progress: 0,
      roles: ["Marketing Manager", "PR Specialist", "A&R Representative"],
      subtasks: [
        { id: 17.1, name: "Track streaming numbers and sales figures", duration: "Ongoing", roles: ["Marketing Manager"] },
        { id: 17.2, name: "Analyze audience demographics and engagement", duration: "Ongoing", roles: ["Marketing Manager"] },
        { id: 17.3, name: "Gather and review critical reception and fan feedback", duration: "Ongoing", roles: ["PR Specialist", "A&R Representative"] }
      ]
    },
    {
      id: 18,
      name: "Lessons Learned",
      phase: "Post-Release Evaluation",
      start: "TBD",
      end: "TBD",
      progress: 0,
      roles: ["Executive Producer", "Production Coordinator", "A&R Representative"],
      subtasks: [
        { id: 18.1, name: "Conduct team debrief on project execution", duration: "Ongoing", roles: ["Executive Producer"] },
        { id: 18.2, name: "Document successes and areas for improvement", duration: "Ongoing", roles: ["Executive Producer", "Production Coordinator"] },
        { id: 18.3, name: "Develop recommendations for future projects", duration: "Ongoing", roles: ["Executive Producer", "A&R Representative"] }
      ]
    },
  ],
  roles: [
    { id: 1, title: "Executive Producer", members: [] },
    { id: 2, title: "A&R Representative", members: [] },
    { id: 3, title: "Lead Producer", members: [] },
    { id: 4, title: "Artist (0108 SLATAN)", members: [] },
    { id: 5, title: "Songwriters", members: [] },
    { id: 6, title: "Arrangers", members: [] },
    { id: 7, title: "Production Coordinator", members: [] },
    { id: 8, title: "Studio Manager", members: [] },
    { id: 9, title: "Sound Engineer", members: [] },
    { id: 10, title: "Assistant Engineers", members: [] },
    { id: 11, title: "Session Musicians", members: [] },
    { id: 12, title: "Mixing Engineer", members: [] },
    { id: 13, title: "Mastering Engineer", members: [] },
    { id: 14, title: "Graphic Designer", members: [] },
    { id: 15, title: "Legal Advisor", members: [] },
    { id: 16, title: "Marketing Manager", members: [] },
    { id: 17, title: "PR Specialist", members: [] },
  ]
};