import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Progress } from "./ui/progress";
import { Checkbox } from "./ui/checkbox";
import { ScrollArea } from "./ui/scroll-area";

const RoleDetailsDialog = ({ selectedRole, roleData, handleTaskCheck, onClose }) => {
  if (!selectedRole) return null;

  return (
    <Dialog open={selectedRole !== null} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] md:max-w-[600px] lg:max-w-[800px] w-[90vw] max-h-[90vh] overflow-hidden flex flex-col">
        <DialogHeader>
          <DialogTitle className="text-xl md:text-2xl">{selectedRole}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="flex-grow mt-4 pr-4">
          <h3 className="font-semibold mb-2 text-lg">Tasks:</h3>
          <ul className="space-y-4">
            {roleData[selectedRole].details.map((detail) => (
              <li key={detail.id} className="bg-slate-50 p-3 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <p className="font-medium text-sm md:text-base">{detail.task}</p>
                  <Checkbox
                    id={`role-task-${detail.id}`}
                    checked={detail.progress === 100}
                    onCheckedChange={(checked) => handleTaskCheck(detail.id, checked)}
                  />
                </div>
                <p className="text-xs md:text-sm text-gray-600">{detail.phase}</p>
                <p className="text-xs md:text-sm text-gray-600 mb-2">{detail.start} - {detail.end}</p>
                <Progress value={detail.progress} className="h-2 md:h-3" />
              </li>
            ))}
          </ul>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default RoleDetailsDialog;