import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import RoleDetailsDialog from './RoleDetailsDialog';

const RoleView = ({ projectData, handleTaskCheck }) => {
  const [selectedRole, setSelectedRole] = useState(null);

  const roleData = projectData.tasks.reduce((acc, task) => {
    task.roles.forEach(role => {
      if (!acc[role]) acc[role] = { tasks: 0, details: [] };
      acc[role].tasks += 1;
      acc[role].details.push({
        id: task.id,
        task: task.name,
        phase: task.phase,
        start: task.start,
        end: task.end,
        progress: task.progress
      });
    });
    return acc;
  }, {});

  const roleChartData = Object.entries(roleData).map(([name, data]) => ({ name, tasks: data.tasks }));

  const handleRoleClick = (role) => {
    setSelectedRole(role);
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={roleChartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="tasks" fill="#8884d8" onClick={(data) => handleRoleClick(data.name)} />
        </BarChart>
      </ResponsiveContainer>
      <RoleDetailsDialog 
        selectedRole={selectedRole} 
        roleData={roleData} 
        handleTaskCheck={handleTaskCheck}
        onClose={() => setSelectedRole(null)}
      />
    </>
  );
};

export default RoleView;