import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import SummaryView from './SummaryView';
import TaskView from './TaskView';
import RoleView from './RoleView';
import PulsarProductionRoles from './PulsarProductionRoles';
import { initialProjectData } from '../data/projectData';
import BudgetTracker from './BudgetTracker'; 

const PulsarAlbumBrief = () => {
  const [selectedPhase, setSelectedPhase] = useState("All");
  const [projectData, setProjectData] = useState(initialProjectData);

  useEffect(() => {
    const savedData = localStorage.getItem('projectData');
    if (savedData) {
      setProjectData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('projectData', JSON.stringify(projectData));
  }, [projectData]);

  const handleTaskCheck = (taskId, isChecked) => {
    setProjectData(prevData => ({
      ...prevData,
      tasks: prevData.tasks.map(task => 
        task.id === taskId ? { ...task, progress: isChecked ? 100 : 0 } : task
      )
    }));
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">0108 SLATAN Project Tracking</h1>
      <Tabs defaultValue="summary">
        <TabsList>
          <TabsTrigger value="summary">Summary</TabsTrigger>
          <TabsTrigger value="tasks">Tasks</TabsTrigger>
          <TabsTrigger value="roles">Roles</TabsTrigger>
          <TabsTrigger value="team">Team</TabsTrigger>
          <TabsTrigger value="budget">Budget</TabsTrigger>
        </TabsList>
        <TabsContent value="summary">
          <SummaryView projectData={projectData} />
        </TabsContent>
        <TabsContent value="tasks">
          <div className="mb-4">
            <Select onValueChange={setSelectedPhase} defaultValue={selectedPhase}>
              <SelectTrigger>
                <SelectValue placeholder="Select phase" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="All">All Phases</SelectItem>
                {projectData.phases.map(phase => (
                  <SelectItem key={phase.name} value={phase.name}>{phase.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <TaskView 
            tasks={projectData.tasks} 
            selectedPhase={selectedPhase} 
            handleTaskCheck={handleTaskCheck} 
          />
        </TabsContent>
        <TabsContent value="roles">
          <RoleView projectData={projectData} handleTaskCheck={handleTaskCheck} />
        </TabsContent>
        <TabsContent value="team">
          <PulsarProductionRoles />
        </TabsContent>
        <TabsContent value="budget">
          <BudgetTracker />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default PulsarAlbumBrief;