import React from 'react';
import { Progress } from "./ui/progress";

const SummaryView = ({ projectData }) => {
  const totalTasks = projectData.tasks.length;
  const completedTasks = projectData.tasks.filter(task => task.progress === 100).length;
  const overallProgress = (completedTasks / totalTasks) * 100;

  const phaseProgress = projectData.phases.map(phase => {
    const phaseTasks = projectData.tasks.filter(task => task.phase === phase.name);
    const phaseCompletedTasks = phaseTasks.filter(task => task.progress === 100).length;
    const progress = phaseTasks.length > 0 ? (phaseCompletedTasks / phaseTasks.length) * 100 : 0;
    return { ...phase, progress };
  });

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold mb-2">Overall Progress</h2>
        <Progress value={overallProgress} className="h-6" />
        <p className="mt-2">{completedTasks} out of {totalTasks} tasks completed ({overallProgress.toFixed(1)}%)</p>
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-2">Progress by Phase</h2>
        {phaseProgress.map(phase => (
          <div key={phase.name} className="mb-4">
            <h3 className="font-medium">{phase.name}</h3>
            <p className="text-sm text-gray-600 mb-1">{phase.start} - {phase.end}</p>
            <Progress value={phase.progress} className="h-4" />
            <p className="text-sm mt-1">{phase.progress.toFixed(1)}% complete</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryView;