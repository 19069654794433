import React, { useState } from 'react';
import { Progress } from "./ui/progress";
import { Checkbox } from "./ui/checkbox";
import * as Collapsible from '@radix-ui/react-collapsible';
import { ChevronDown, ChevronUp } from 'lucide-react';

const TaskView = ({ tasks, selectedPhase, handleTaskCheck }) => {
  const [openTasks, setOpenTasks] = useState({});

  const filteredTasks = selectedPhase === "All" 
    ? tasks 
    : tasks.filter(task => task.phase === selectedPhase);

  const toggleTask = (taskId) => {
    setOpenTasks(prev => ({
      ...prev,
      [taskId]: !prev[taskId]
    }));
  };

  return (
    <div className="space-y-4">
      {filteredTasks.map((task) => (
        <Collapsible.Root
          key={task.id}
          open={openTasks[task.id]}
          onOpenChange={() => toggleTask(task.id)}
        >
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="flex items-center justify-between">
              <h3 className="font-semibold">{task.name}</h3>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={task.progress === 100}
                  onCheckedChange={(checked) => handleTaskCheck(task.id, checked)}
                />
                <Collapsible.Trigger asChild>
                  <button className="p-1 hover:bg-slate-100 rounded-full">
                    {openTasks[task.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                </Collapsible.Trigger>
              </div>
            </div>
            <p className="text-sm text-gray-600">{task.start} - {task.end}</p>
            <Progress value={task.progress} className="mt-2" />
            <p className="text-sm mt-2">Roles: {task.roles.join(", ")}</p>
          </div>
          <Collapsible.Content>
            <div className="pl-4 mt-2 space-y-2">
              {task.subtasks && task.subtasks.length > 0 ? (
                task.subtasks.map(subtask => (
                  <div key={subtask.id} className="bg-slate-50 p-2 rounded border border-slate-200">
                    <p className="text-sm font-medium">{subtask.name}</p>
                    <p className="text-xs text-gray-600">Duration: {subtask.duration}</p>
                    <p className="text-xs text-gray-600">Roles: {subtask.roles.join(', ')}</p>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-600">No subtasks available for this task</p>
              )}
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      ))}
    </div>
  );
};

export default TaskView;