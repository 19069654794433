import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Plus, X } from 'lucide-react';

const initialRoles = [
    {
      category: "Executive Level",
      roles: [
        {
          title: "Executive Producer",
          description: "Oversee the entire 'JERAT BINTANG' album project, ensuring its alignment with 0108 SLATAN's vision and financial viability.",
          responsibilities: [
            "Make high-level creative and financial decisions",
            "Approve budgets and allocate resources",
            "Mediate conflicts and make final decisions on creative disputes",
            "Ensure the project meets quality standards and deadlines",
            "Liaise with A&R and marketing teams to align the project with label strategy"
          ],
          kpis: [
            "Project completed within budget and timeline",
            "Album quality meets or exceeds industry standards",
            "Successful collaboration between all teams involved"
          ],
          assignedTo: []
        },
        {
          title: "A&R Representative",
          description: "Ensure 'JERAT BINTANG' aligns with 0108 SLATAN's artistic vision and market strategy.",
          responsibilities: [
            "Provide input on song selection and album direction",
            "Identify and suggest potential collaborations or featured artists",
            "Offer feedback on tracks throughout the production process",
            "Coordinate with marketing to develop promotion strategies",
            "Act as a bridge between the creative team and label management"
          ],
          kpis: [
            "Album cohesively represents 0108 SLATAN's vision",
            "Successful integration of traditional and modern elements",
            "Positive reception from target audience and critics"
          ],
          assignedTo: []
        }
      ]
    },
    {
      category: "Creative Team",
      roles: [
        {
          title: "Lead Producer",
          description: "Lead the music production process for 'JERAT BINTANG,' realizing 0108 SLATAN's vision of blending traditional Malaysian music with contemporary hip-hop and R&B.",
          responsibilities: [
            "Oversee recording, mixing, and mastering of tracks",
            "Collaborate with artists and songwriters on compositions and arrangements",
            "Manage studio sessions and ensure timely completion of recordings",
            "Make critical decisions to enhance the album's overall sound",
            "Work with session musicians and vocalists to capture best performances"
          ],
          kpis: [
            "High-quality sound production aligning with album's artistic direction",
            "Successful fusion of traditional and modern musical elements",
            "Timely completion of production milestones",
            "Positive feedback from artists and label on final product"
          ],
          assignedTo: []
        },
        {
          title: "Artist",
          description: "Provide creative direction and perform on the 'JERAT BINTANG' album, embodying the fusion of traditional Malaysian and contemporary music.",
          responsibilities: [
            "Collaborate on songwriting and arrangement decisions",
            "Perform vocals and potentially instrumentals for the album",
            "Provide input on the overall sound and direction of the album",
            "Participate in marketing and promotion activities"
          ],
          kpis: [
            "Delivery of high-quality performances",
            "Successful expression of personal artistic vision",
            "Active and productive collaboration with production team"
          ],
          assignedTo: []
        },
        {
          title: "Songwriters",
          description: "Create lyrics and melodies that reflect the themes of self-discovery and personal growth while incorporating elements of Malaysian culture.",
          responsibilities: [
            "Develop lyrics and melodies for the album tracks",
            "Collaborate with 0108 SLATAN and producers to refine songs",
            "Ensure lyrics align with the album's themes and concept",
            "Incorporate traditional Malaysian elements into contemporary song structures"
          ],
          kpis: [
            "Creation of cohesive and impactful lyrics across the album",
            "Successful blend of traditional and modern lyrical elements",
            "Positive reception of songs by artist, producers, and test audiences"
          ],
          assignedTo: []
        },
        {
          title: "Arrangers",
          description: "Create musical arrangements that seamlessly blend traditional Malaysian elements with contemporary hip-hop and R&B sounds.",
          responsibilities: [
            "Develop arrangements for each track, incorporating both traditional and modern instruments",
            "Collaborate with producers and songwriters to enhance the overall sound",
            "Ensure arrangements support the lyrical themes and energy of each song",
            "Adapt traditional Malaysian musical elements for contemporary production"
          ],
          kpis: [
            "Successful integration of traditional and modern musical elements",
            "Arrangements that enhance and support the songs' messages",
            "Positive feedback from production team and artist on arrangements"
          ],
          assignedTo: []
        }
      ]
    },
    {
      category: "Technical Team",
      roles: [
        {
          title: "Sound Engineer",
          description: "Ensure high-quality recording, mixing, and mastering of the 'JERAT BINTANG' album.",
          responsibilities: [
            "Set up and operate recording equipment",
            "Capture high-quality recordings of vocals, instruments, and samples",
            "Mix tracks to achieve desired sound balance and effects",
            "Collaborate with the producer to realize the intended sound for each track",
            "Oversee the mastering process for consistent sound quality across the album"
          ],
          kpis: [
            "High-quality, clear, and well-balanced recordings",
            "Mixes that effectively blend traditional and modern elements",
            "Consistent sound quality across the entire album",
            "Timely completion of recording, mixing, and mastering phases"
          ],
          assignedTo: []
        },
        {
          title: "Assistant Engineers",
          description: "Support the sound engineer in all technical aspects of recording and production.",
          responsibilities: [
            "Assist in studio setup and equipment maintenance",
            "Help with microphone placement and signal routing",
            "Manage recording sessions and maintain session logs",
            "Assist in organizing and managing audio files",
            "Provide technical support during mixing and mastering phases"
          ],
          kpis: [
            "Efficient support leading to smooth recording sessions",
            "Accurate documentation of recording sessions",
            "Positive feedback from sound engineer and production team"
          ],
          assignedTo: []
        },
        {
          title: "Session Musicians",
          description: "Perform traditional and modern instrumental parts for the 'JERAT BINTANG' album, contributing to its unique sound.",
          responsibilities: [
            "Perform instrumental parts as directed by producers and arrangers",
            "Provide input on arrangement and performance of traditional Malaysian instruments",
            "Collaborate with other musicians to create cohesive performances",
            "Be available for re-records or overdubs as needed"
          ],
          kpis: [
            "High-quality performances that enhance the album's sound",
            "Successful integration of traditional instruments into modern productions",
            "Timely completion of recording parts",
            "Positive feedback from production team on contributions"
          ],
          assignedTo: []
        }
      ]
    },
    {
      category: "Production Support",
      roles: [
        {
          title: "Studio Manager",
          description: "Ensure smooth operation of studio facilities throughout the 'JERAT BINTANG' album production.",
          responsibilities: [
            "Manage studio bookings and scheduling",
            "Oversee studio equipment maintenance and upgrades",
            "Coordinate with technical team on studio setup requirements",
            "Manage studio budget and resources",
            "Ensure a comfortable and productive studio environment"
          ],
          kpis: [
            "Efficient use of studio time and resources",
            "Minimal technical issues or delays due to studio-related problems",
            "Positive feedback from production team on studio operations"
          ],
          assignedTo: []
        },
        {
          title: "Production Coordinator",
          description: "Manage logistics and scheduling for the 'JERAT BINTANG' album production.",
          responsibilities: [
            "Create and maintain production schedules",
            "Coordinate between different teams and individuals involved in the project",
            "Manage resources and track project progress",
            "Organize travel and accommodation for out-of-town participants",
            "Handle administrative tasks related to the production"
          ],
          kpis: [
            "Adherence to production timeline",
            "Efficient coordination leading to smooth workflow",
            "Timely resolution of logistical issues",
            "Positive feedback from team members on organizational aspects"
          ],
          assignedTo: []
        },
        {
          title: "Legal Advisor",
          description: "Manage legal aspects of the 'JERAT BINTANG' album production to protect 0108 SLATAN's interests.",
          responsibilities: [
            "Draft and review contracts for all parties involved in the production",
            "Ensure proper licensing for any samples or covers used",
            "Manage copyright registrations for new works",
            "Advise on legal issues related to collaboration and featured artists",
            "Ensure compliance with relevant music industry regulations"
          ],
          kpis: [
            "All necessary contracts and agreements in place before production begins",
            "Proper licensing secured for all music used",
            "Timely registration of copyrights",
            "No legal disputes arising from the production process"
          ],
          assignedTo: []
        }
      ]
    },
    {
      category: "Post-Production Team",
      roles: [
        {
          title: "Mixing Engineer",
          description: "Create final mixes for each track on the 'JERAT BINTANG' album that realize the creative vision.",
          responsibilities: [
            "Balance and enhance individual tracks within each song",
            "Apply appropriate effects and processing to each element",
            "Ensure clarity and separation between different instruments and vocals",
            "Collaborate with the producer and artist to achieve desired sound",
            "Prepare sessions for mastering"
          ],
          kpis: [
            "High-quality mixes that enhance the songs' impact",
            "Successful balance of traditional and modern elements in the mix",
            "Timely delivery of final mixes",
            "Positive feedback from producer, artist, and label on mix quality"
          ],
          assignedTo: []
        },
        {
          title: "Mastering Engineer",
          description: "Ensure consistent sound quality and optimal playback across various systems for the 'JERAT BINTANG' album.",
          responsibilities: [
            "Apply final processing to mixed tracks for optimal sound",
            "Ensure consistency in volume and tone across the album",
            "Prepare different masters for various release formats (streaming, CD, vinyl)",
            "Address any technical issues in the final mixes",
            "Provide quality control for the final product"
          ],
          kpis: [
            "Consistent and professional sound across the entire album",
            "Masters that translate well across different playback systems",
            "Timely delivery of masters for various formats",
            "Positive feedback on overall sound quality of the album"
          ],
          assignedTo: []
        }
      ]
    },
    {
      category: "Marketing and PR",
      roles: [
        {
          title: "Marketing Manager",
          description: "Develop and implement a marketing strategy to promote 'JERAT BINTANG' and achieve commercial success.",
          responsibilities: [
            "Create a comprehensive marketing plan for the album",
            "Coordinate with digital platforms for album release and promotion",
            "Develop social media and content marketing strategies",
            "Plan and oversee album launch events",
            "Analyze market trends and adjust strategies accordingly"
          ],
          kpis: [
            "Successful execution of marketing plan within budget",
            "Achieve target numbers for album sales/streams",
            "Increased visibility and following for 0108 SLATAN",
            "Positive reception and engagement from target audience"
          ],
          assignedTo: []
        },
        {
          title: "PR Specialist",
          description: "Generate positive publicity and media coverage for 'JERAT BINTANG' and 0108 SLATAN.",
          responsibilities: [
            "Develop and maintain relationships with media outlets and influencers",
            "Write and distribute press releases and media kits",
            "Arrange interviews and media appearances for 0108 SLATAN",
            "Manage crisis communications if needed",
            "Monitor and report on media coverage and public reception"
          ],
          kpis: [
            "Amount and quality of media coverage generated",
            "Successful placement of interviews and features",
            "Positive sentiment in media coverage and public discussion",
            "Effective management of any PR challenges or crises"
          ],
          assignedTo: []
        }
      ]
    }
  ];

  const RoleCard = ({ role, updateAssignedTo }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [editingIndex, setEditingIndex] = useState(-1);
  
    const handleAddName = () => {
      if (newName.trim()) {
        updateAssignedTo([...role.assignedTo, newName.trim()]);
        setNewName('');
      }
    };
  
    const handleRemoveName = (index) => {
      const updatedNames = role.assignedTo.filter((_, i) => i !== index);
      updateAssignedTo(updatedNames);
    };
  
    const handleEditName = (index, newValue) => {
      const updatedNames = [...role.assignedTo];
      updatedNames[index] = newValue;
      updateAssignedTo(updatedNames);
    };
  
    return (
      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
        <div 
          className="flex justify-between items-center cursor-pointer" 
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3 className="text-lg font-semibold">{role.title}</h3>
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {isOpen && (
          <div className="mt-4">
            <p className="mb-2">{role.description}</p>
            <h4 className="font-semibold mt-4 mb-2">Assigned To:</h4>
            <ul className="list-none pl-0 mb-4">
              {role.assignedTo.map((name, index) => (
                <li key={index} className="flex items-center mb-2">
                  {editingIndex === index ? (
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => handleEditName(index, e.target.value)}
                      onBlur={() => setEditingIndex(-1)}
                      autoFocus
                      className="border rounded px-2 py-1 mr-2"
                    />
                  ) : (
                    <span onClick={() => setEditingIndex(index)} className="cursor-pointer mr-2">{name}</span>
                  )}
                  <button onClick={() => handleRemoveName(index)} className="text-red-500 ml-2">
                    <X size={16} />
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex mb-4">
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder="Add new name"
                className="border rounded px-2 py-1 mr-2"
              />
              <button onClick={handleAddName} className="bg-blue-500 text-white px-2 py-1 rounded">
                <Plus size={20} />
              </button>
            </div>
            <h4 className="font-semibold mt-4 mb-2">Responsibilities:</h4>
            <ul className="list-disc pl-5 mb-4">
              {role.responsibilities.map((resp, index) => (
                <li key={index}>{resp}</li>
              ))}
            </ul>
            <h4 className="font-semibold mt-4 mb-2">KPIs:</h4>
            <ul className="list-disc pl-5">
              {role.kpis.map((kpi, index) => (
                <li key={index}>{kpi}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };
  
  const PulsarProductionRoles = () => {
    const [roles, setRoles] = useState(() => {
      const savedRoles = localStorage.getItem('pulsarProductionRoles');
      return savedRoles ? JSON.parse(savedRoles) : initialRoles;
    });
    const [activeCategory, setActiveCategory] = useState(roles[0].category);
  
    useEffect(() => {
      localStorage.setItem('pulsarProductionRoles', JSON.stringify(roles));
    }, [roles]);
  
    const updateAssignedTo = (categoryIndex, roleIndex, newAssignedTo) => {
      const updatedRoles = [...roles];
      updatedRoles[categoryIndex].roles[roleIndex].assignedTo = newAssignedTo;
      setRoles(updatedRoles);
    };
  
    return (
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-center"></h1>
        <div className="mb-6 flex flex-wrap justify-center">
          {roles.map((category) => (
            <button
              key={category.category}
              className={`px-4 py-2 rounded-full mr-2 mb-2 ${
                activeCategory === category.category
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700'
              }`}
              onClick={() => setActiveCategory(category.category)}
            >
              {category.category}
            </button>
          ))}
        </div>
        <div>
          {roles
            .find((category, index) => category.category === activeCategory)
            .roles.map((role, roleIndex) => (
              <RoleCard
                key={role.title}
                role={role}
                updateAssignedTo={(newAssignedTo) => 
                  updateAssignedTo(
                    roles.findIndex(cat => cat.category === activeCategory),
                    roleIndex,
                    newAssignedTo
                  )
                }
              />
            ))}
        </div>
      </div>
    );
  };
  
  export default PulsarProductionRoles;